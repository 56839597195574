import { useNavigate } from "react-router-dom";
import { mdiArrowLeft } from "@mdi/js";
import HButton from "../../../components/common/hbutton/HButton";
import { MenuLanguage } from "../../../components/menus/MenuLanguage";
import HolaflyLogo from "../../../holafly_logo.svg";

export const NavbarHelpCenter = () => {
  const navigate = useNavigate();

  const handleGoBack = (): void => navigate("/");

  return (
    <div className="2xl:w-[60%] 2xl:px-0 md:px-[100px] py-2  md:py-[54px] flex justify-between items-center w-full ">
      <HButton
        type="button"
        name="button go back"
        className="flex justify-center items-center h-12 w-12 rounded-xl border-[0.7px] border-neutral-300 hover:bg-neutral-200"
        icon={mdiArrowLeft}
        onClick={handleGoBack}
      />

      <img
        draggable={false}
        className="h-[25px] md:h-[30px]"
        src={HolaflyLogo}
        alt="logo_Holafly"
      />
      <MenuLanguage />
    </div>
  );
};
