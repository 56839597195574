import { t } from "i18next";
import { LanguageSelector } from "../../../components/languageSelector/LanguageSelector";
import { useCustomerStore } from "../../../store/customerStore";
import {IChildren, ICustomerStore } from "../../../interfaces";
import Map from "../../../map.svg";
import GirlPcPhone from "../../../girl-pc-phone.svg";

export const HelpCenterHeader = ({children}:IChildren): JSX.Element => {
  const { isOpenMenuLanguage, toggleMenuLanguage } = useCustomerStore(
    (store: ICustomerStore) => store
  );
  return (
    <div className="px-2 z-1 h-[25rem] md:h-[41rem] lg:h-[33rem] w-full flex flex-col justify-start items-center">
      <img
        draggable={false}
        className="absolute opacity-50 -z-1 object-cover h-[184px] w-[286px]  md:w-[654px] md:h-[420px]"
        src={Map}
        alt="Not found"
      />
      {children}
      <div
        className="w-full lg:w-[77%] xl:w-[70%] 2xl:w-[60%] 2xl:justify-between 
      flex flex-col items-center lg:flex-row md:justify-center lg:gap-20 p-2">
        <div className="flex flex-col items-center lg:items-start md:justify-center max-w-lg">
          <h1 className="font-medium text-[34px] md:text-[48px] text-center mt-[40px]">
            {t("global.login.frequentDoubts.title")}
          </h1>
          <h3 className="font-medium text-lg md:text-[22px] text-center md:text-start">
            {t("global.login.frequentDoubts.description")}
          </h3>
        </div>
        <img
          draggable={false}
          className=" h-[200px] w-[200px] md:h-[398px] md:w-[398px]"
          src={GirlPcPhone}
          alt="girl_image"
        />
      </div>

      {isOpenMenuLanguage && (
        <LanguageSelector
          handleChangeVisible={() => toggleMenuLanguage(false)}
        />
      )}
    </div>
  );
};
